import React, { useState, useEffect } from "react";

import {  withRouter } from "react-router-dom";

import Header2 from "@/components/Header/Header";
import './BIMCenter.less';


function HomeRouter() {
  

  useEffect(() => {


  }, []);




  return (
    <Header2 index={2}>
   <div className="BIMCenter">   {/*   */}
      

    </div>
</Header2>
  )
}

export default withRouter(HomeRouter);
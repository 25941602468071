import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import './earth.less'

// import * as echarts from 'echarts';

import * as THREE from './three.module.js';

import { OrbitControls } from './OrbitControls.js';


import { CSS2DRenderer, CSS2DObject } from './threeSouce/CSS2DRenderer.js';

import * as d3 from 'd3';

import full from './full.json';


// 地球圆圈
import img3 from '@/assets/img/earth/1.png';
 
import tu1 from '@/assets/img/earth/tu1.png';

// 圆柱
import img4 from '@/assets/img/earth/121.png';
const total = 1000;
const height = 160;
const width = 160;
const depth = 160;



function Earth(props) {
    let container  = null
    let map = null,
    renderer = null,
    scene = null,
    camera = null,
    controller = null,
    raycaster = null,
    mouse = null,
    eventOffset = null,
    provinceInfo = null,
    controls = null,
    activeInstersect = [],
    uniform = {},
    light1 = null,
    light2 = null,
    light3 = null,
    light4 = null,
    object = null,
    texture = null,
    object1 = null,
    object2 = null,
    object3 = null,
    object4 = null,
    circle1 = null,
    circle2 = null,
    circle3 = null,
    circle4 = null,
    pointScene = null,
    CylinderObject = new THREE.Object3D(),
    
    bottomCicleObj = new THREE.Object3D(), 
    circleObj = new THREE.Object3D(),
    AnimationFrameRender = null,
    AnimationFrameAll = null,
    labelRenderer = null

    const [propsState, setPropsState] = useState(props)

    useEffect(() => {

      getgetThreeEarth()

      
      return () => {
        cancelAnimationFrame(AnimationFrameRender); 
        cancelAnimationFrame(AnimationFrameAll); 
        window.removeEventListener('mousemove', () => {

        }) 

        clearDom()

      }

      }, propsState)

    function clearDom() {
      container  = null
      map = null
      renderer = null
      scene = null
      camera = null
      controller = null
      raycaster = null
      // mouse = null
      // eventOffset = null
      provinceInfo = null
      controls = null
      activeInstersect = null
      uniform = null
      light1 = null
      light2 = null
      light3 = null
      light4 = null
      object = null
      texture = null
      object1 = null
      object2 = null
      object3 = null
      object4 = null
      circle1 = null
      circle2 = null
      circle3 = null
      circle4 = null
      bottomCicleObj = null
      circleObj = null
      AnimationFrameRender = null
      AnimationFrameAll = null

    }

    
    // three.js 模型 
    function getgetThreeEarth() {
      container = document.getElementById("containerHHback");

      renderer = new THREE.WebGLRenderer({
        antialias:true,
      });
      
      renderer.setClearColor('#0D1E37', 1.0);
      

      renderer.setSize(window.innerWidth, window.innerHeight);
      container.appendChild(renderer.domElement);


      labelRenderer = new CSS2DRenderer();
      labelRenderer.setSize( window.innerWidth, window.innerHeight );
      labelRenderer.domElement.style.position = 'absolute';
      labelRenderer.domElement.style.top = '0px';
      container.appendChild( labelRenderer.domElement );



      scene = new THREE.Scene();
      
      scene.position.x = 0;

      var directionalLight = new THREE.DirectionalLight( 0xffffff );
      directionalLight.position.set( 1, 0.75, 0.5 ).normalize();
      scene.add( directionalLight );





      // var cylinderGeo = new THREE.CylinderGeometry(15, 15 ,40 ,40 ,40);
      //   var cylinderMat = new THREE.MeshLambertMaterial({//创建材料
      //       color:0xFF6600,
      //       wireframe:false
      //   });
      //   //创建圆柱体网格模型
      //   var cylinderMesh = new THREE.Mesh(cylinderGeo, cylinderMat);
      //   cylinderMesh.position.set(0, 20, 10);//设置圆柱坐标
      //   scene.add(cylinderMesh);


      scene.position.x = -10;

      scene.add(circleObj)
      scene.add(CylinderObject)

      
 
      camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.set(0, -50, 40);
      camera.lookAt(0, 0, 0);

      //设置控制
      setController()

      // 设置光照
      setLight()

      // 设置射线
      setRaycaster()

      // 设置动画
      animate()

      // 加载地图
      loadMapData(full);

      // 加载圆圈
      scatterCircle(1, 0.1, 0.1, new THREE.Vector3(0, 1, 1), 0.004);
      // circle.scale.x = circle.scale.y = circle.scale.z = xScale;
      // circle.position.set(x, y, z);
      // scene.add(circle);
    
      // 加载粒子

      // 加载圆圈
      setCircleImg()


      // 加载发光物体


      // 加载光点
      // sphereLight()

      // 加载模型
      loadModle()



      let Cylinder =  loadCylinder(10)
      Cylinder.position.set(-3, -22, 6);//设置圆柱坐标
      
      CylinderObject.add(Cylinder)

      let earthLabel = addFontThree('云南省参建人数', '1210')
      earthLabel.position.set(0, -22, 10);
      CylinderObject.add( earthLabel );  


    
      let Cylinder2 =  loadCylinder(20)  // Cylinder.clone()
      Cylinder2.position.set(0, -12, 8);//设置圆柱坐标 
      CylinderObject.add(Cylinder2)
      let earthLabel2 = addFontThree('四川省参建人数', '1510')
      earthLabel2.position.set(4, -8, 8);
      CylinderObject.add( earthLabel2 );  
      

      let Cylinder3 =  loadCylinder(15)  //Cylinder.clone()
      Cylinder3.position.set(13, -23, 7);//设置圆柱坐标
      CylinderObject.add(Cylinder3)
      let earthLabel3 = addFontThree('广东省参建人数', '1810')
      earthLabel3.position.set(17.5, -20, 7);
      CylinderObject.add( earthLabel3 );  
   


      let Cylinder4 =  loadCylinder(25)  //Cylinder.clone()
      Cylinder4.position.set(12, -7, 8.5);//设置圆柱坐标
      CylinderObject.add(Cylinder4)
      let earthLabel4 = addFontThree('河南省参建人数', '1310')
      earthLabel4.position.set(17, -3, 8.5);
      CylinderObject.add( earthLabel4 );  


      // 加载人的模型
      

      // scene.add(addPersion())

      // 加载粒子
      addParticle()
    }


    // 添加粒子
    function addParticle() {



      const geometry = new THREE.Geometry();
      for (let i = 0; i < total; i++) {
        const vertex = new THREE.Vector3();
        const x = Math.random() * width - width * 0.5;
        const y = Math.random() * height - height * 0.5;
        const z = Math.random() * depth - depth * 0.5;

        vertex.x = x;
        vertex.y = y;
        vertex.z = z;

        vertex.origin = new THREE.Vector3();
        vertex.origin.x = x;
        vertex.origin.y = y;
        vertex.origin.z = z;

        vertex.direction = new THREE.Vector3();
        vertex.direction.x = Math.random() - 0.5;
        vertex.direction.y = Math.random() - 0.5;
        vertex.direction.z = Math.random() - 0.5;
        geometry.vertices.push(vertex);
      }

      const material = new THREE.PointsMaterial({
        size: 0.2,
        color: 0x00ffff,
        fog: true,
      });

      const points = new THREE.Points(geometry, material);

      console.log(points)
      pointScene = points;
      scene.add(points);


    }

    // 添加人物
    function addPersion() {
      let persionObjectGRoup = new THREE.Object3D()


      let texture = THREE.ImageUtils.loadTexture(img4, {}, function() {
          
      });


      var points = [];
      for ( var i = 0; i < 10; i ++ ) {
        points.push( new THREE.Vector2( Math.sin( i * 0.2 ) * 10, ( i - 5 ) * 2 ) );
      }
      var geometry2 = new THREE.LatheGeometry( points );
      var material2 = new THREE.MeshBasicMaterial( { map: texture, transparent: true, opacity: 0.9, } );
      var lathe = new THREE.Mesh( geometry2, material2 );
      lathe.scale.x = lathe.scale.y = lathe.scale.z = 0.5;
      // persionObjectGRoup.add( lathe );


      let geometry = new THREE.SphereGeometry( 1, 32, 32 );
      let material = new THREE.MeshLambertMaterial({
          // map: texture,
          color: '#064CF3',
          transparent: true,
          // wireframe:true,
          opacity: 1.0,
          shininess: 100, flatShading: true
    });
      let sphere = new THREE.Mesh( geometry, material );
      sphere.scale.x = sphere.scale.y = sphere.scale.z = 0.3;




    persionObjectGRoup.position.z = 10 
    persionObjectGRoup.position.y = -25

    persionObjectGRoup.add(sphere)

      return persionObjectGRoup;
    }




    // 加载圆柱体
    function loadCylinder(height) {
      let cylinderGeo  = new THREE.CylinderGeometry(1.2, 1.2 ,height ,20 ,20)

      var texture = THREE.ImageUtils.loadTexture(img4, {}, function() {
          
      });



      let cylinderMat = new THREE.MeshLambertMaterial({
            map: texture,
            transparent: true,
            // wireframe:true,
            opacity: 0.9
      });



    //创建圆柱体网格模型
    let cylinderMesh = new THREE.Mesh(cylinderGeo, cylinderMat);

    cylinderMesh.castShadow = true;
		cylinderMesh.receiveShadow = true;
    
    cylinderMesh.rotateX(Math.PI/180 * 90)
    cylinderMesh.rotateY(Math.PI/180 * 80)
    
    cylinderMesh.scale.x = cylinderMesh.scale.y = cylinderMesh.scale.z = 0.3; 

    return cylinderMesh

    // scene.add(cylinderMesh);//向场景添加圆柱体

    }


    function addFontThree(name, number) {
      const earthDiv = document.createElement( 'div' );
      let p = document.createElement( 'p' );
      let p2 = document.createElement( 'p' );
      let span = document.createElement( 'span' );
      let i = document.createElement( 'i' );

      p2.className = 'number';
      earthDiv.appendChild(p)
      earthDiv.appendChild(p2)
      p2.appendChild(span)
      p2.appendChild(i)

      span.textContent = number
      i.textContent = '人'

      earthDiv.className = 'ThreeLabel';
      
      p.textContent = name;
      
      earthDiv.style.marginTop = '-1em';
      const earthLabel = new CSS2DObject( earthDiv );
      
      return earthLabel;
    }

 
    // 加载字体
    function addCanvas(fontText) {

      return new Promise(reslove => {
        // let image = new Image();
        // image.src= img31;
        // image.onload =()=>{
          var canvas = document.createElement('canvas');
          // const {width, height} = image;
          // canvas.style.width = width+'px';
          // canvas.style.height = height+'px';

          const width = 200
          const height = 150


          var scale = window.devicePixelRatio;
           var scale = 1; //我为什么用2，而不用devicePixelRatio数值？因为我的电脑Win10系统设置了自定义缩放比大于devicePixelRatio但小于2（200%）
          canvas.width = Math.floor(width*scale);
          canvas.height = Math.floor(height*scale);
          
          var ctx = canvas.getContext('2d');
          ctx.fillStyle = "rgba(0,0,0, 0.8)";
			    ctx.fillRect(0, 0, width, height);
          

          
          ctx.scale(scale, scale);//scale>1表示放大，反之表示缩小。即绘制原先1px现在是绘制scale个pixel　　 //注意绘制内容在scale之后开始
          // ctx.drawImage(image, 0, 0);

          ctx.fillStyle = '#ffffff';
          ctx.font='20px Arial';
          ctx.textAlign = 'center';
          // ctx.textBaseline = 'middle';
          var x = canvas.width/2;
          var y = canvas.height/2;
          ctx.fillText(fontText, x/scale, y/scale);//为什么x/y还除以scale，因为画布实际放大了scale倍，所以x,y绘制的像素也放大了scale，所以再除以scale
          // var image1 = new Image();
          // image1.src = canvas.toDataURL();
          let  geometry=new THREE.CubeGeometry(20,10,0);
          let texture=new THREE.Texture(canvas);
          let material=new THREE.MeshBasicMaterial({map:texture, transparent: true});
          texture.needsUpdate=true;//开启纹理更新
          let mesh=new THREE.Mesh(geometry,material);
          reslove(mesh)
        // }
      });
    }


    function loadModle() {
      const ObjGroup = new THREE.Object3D()
      ObjGroup.name = 'ObjGroup';
      
      // tu1

      // 加载底部圈圈
      const geometry = new THREE.CircleGeometry(1, 30);

      // // 0C2940

      var texture = THREE.ImageUtils.loadTexture(tu1, {}, function() {
          
      });
      const material = new THREE.MeshLambertMaterial({
            map: texture,
            transparent: true,
      });

      circle1 = new THREE.Mesh(geometry, material);
      circle1.position.set(-3, -22, 5);
      ObjGroup.add(circle1)


      circle2 = circle1.clone()
      circle2.position.set(0, -12, 5);
      ObjGroup.add(circle2)


      circle3 = circle1.clone()
      circle3.position.set(13, -23, 5);
      ObjGroup.add(circle3)


      circle4 =  circle1.clone();
      circle4.position.set(12, -7, 5);
      ObjGroup.add(circle4)


      scene.add(ObjGroup)
    }



    function sphereLight() {

      const lightGroup = new THREE.Object3D()
      lightGroup.name = 'lightGroup'

      const sphere = new THREE.SphereGeometry( 0.5, 16, 8 );

      light1 = new THREE.PointLight( 0xff0040, 2, 50 );
      light1.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0xff0040 } ) ) );
      // scene.add(  );
      lightGroup.add(light1)

      light2 = new THREE.PointLight( 0x0040ff, 2, 50 );
      light2.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0x0040ff } ) ) );
      // scene.add(  );
      lightGroup.add(light2)

      light3 = new THREE.PointLight( 0x80ff80, 2, 50 );
      light3.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0x80ff80 } ) ) );
      // scene.add(  );
      lightGroup.add(light3)

      light4 = new THREE.PointLight( 0xffaa00, 2, 50 );
      light4.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0xffaa00 } ) ) );
      // scene.add(  );
      lightGroup.add(light4)

      scene.add(lightGroup)


    }

    


    
    function setCircleImg() {
      const geometry = new THREE.CircleGeometry(60, 120);

      // 0C2940

      var texture = THREE.ImageUtils.loadTexture(img3, {}, function() {
          
      });
      const material = new THREE.MeshLambertMaterial({
            map: texture,
            transparent: true,
      });

      const circle = new THREE.Mesh(geometry, material);

      circleObj.add(circle)
    }

    

    // 加载圆圈
    function scatterCircle(r, init, ring, color, speed) {
      
      uniform = {
        u_color: { value: color },
        u_r: { value: init },
        u_ring: {
          value: ring,
        },
      };
    
      var vs = `
        varying vec3 vPosition;
        void main(){
          vPosition=position;
          gl_Position	= projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `;
      var fs = `
        varying vec3 vPosition;
        uniform vec3 u_color;
        uniform float u_r;
        uniform float u_ring;
    
        void main(){
          float pct=distance(vec2(vPosition.x,vPosition.y),vec2(0.0));
          if(pct>u_r || pct<(u_r-u_ring)){
            gl_FragColor = vec4(1.0,0.0,0.0,0);
          }else{
            float dis=(pct-(u_r-u_ring))/(u_r-u_ring);
            gl_FragColor = vec4(u_color,dis);
          }
        }
      `;
      const geometry = new THREE.CircleGeometry(r, 120);
      var material = new THREE.ShaderMaterial({
        vertexShader: vs,
        fragmentShader: fs,
        side: THREE.DoubleSide,
        uniforms: uniform,
        transparent: true,
        depthWrite: false,
      });
      const circle = new THREE.Mesh(geometry, material);
      circle.scale.x = circle.scale.y = circle.scale.z = 200;
      // circleObj.rotateX(-Math.PI/180 * 90);
      circleObj.add(circle)
    
      function render() {
        uniform.u_r.value += speed || 0.1;
        if (uniform.u_r.value >= r) {
          uniform.u_r.value = init;
        }
        AnimationFrameRender = requestAnimationFrame(render);
      }
      render();
      return circle;
    }





    
    function loadMapData(chinaJson){

      map = new THREE.Object3D();


      
      const projection = d3.geoMercator().center([104.0, 37.5]).scale(80).translate([0, 0]);



      chinaJson.features.forEach(elem => {
          // 定一个省份3D对象
          const province = new THREE.Object3D();
          // 每个的 坐标 数组
          const coordinates = elem.geometry.coordinates;

        let uv = `
           uniform sampler2D baseTexture;
           uniform sampler2D bloomTexture;
           varying vec2 vUv;
           vec4 getTexture( sampler2D texelToLinearTexture ) {
             return mapTexelToLinear( texture2D( texelToLinearTexture , vUv ) );
           }
          void main() {
             gl_FragColor = ( getTexture( baseTexture ) + vec4( 1.0 ) * getTexture( bloomTexture ) );
          }
        `
        

        // const earthDiv = document.createElement( 'div' );
        // earthDiv.className = 'threeProive';
        // earthDiv.textContent = elem.properties.name;
        //   earthDiv.style.marginTop = '-1em';
        //   const earthLabel = new CSS2DObject( earthDiv );
          

        //   if(elem.properties.center) {
        //     const [px, py] = projection(elem.properties.center)
        //     earthLabel.rotateY = -50;
        //     earthLabel.position.set(px, -py, 0)
            
        //   }


        //   province.add(earthLabel)

        
          


          // 循环坐标数组
          coordinates.forEach(multiPolygon => {

            multiPolygon.forEach(polygon => {
              const shape = new THREE.Shape();

              const lineMaterial = new THREE.LineBasicMaterial({
                color: '#91FFFF'
              });
              // const lineMaterial = new THREE.ShaderMaterial({
              //   vertexShader: vs,
              //   fragmentShader: fs,
              //   side: THREE.DoubleSide,
              //   uniforms: uniform,
              //   transparent: true,
              //   depthWrite: false,
              // });

              

              const lineGeometry = new THREE.Geometry();

             
              for (let i = 0; i < polygon.length; i++) {
                const [x, y] = projection(polygon[i]);
                if (i === 0) {
                  shape.moveTo(x, -y);
                }
                shape.lineTo(x, -y);
                lineGeometry.vertices.push(new THREE.Vector3(x, -y, 4.01));
              }

              const extrudeSettings = {
                depth: 4,
                bevelEnabled: false
              };

              
              const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
              const material = new THREE.MeshBasicMaterial({
                color: '#3A3E44',
                transparent: true,
                opacity: 0.85
              });
              const material1 = new THREE.MeshBasicMaterial({
                color: '#3A3E44',
                transparent: true,
                opacity: 0.85
              });
              const mesh = new THREE.Mesh(geometry, [material, material1]);
              const line = new THREE.Line(lineGeometry, lineMaterial);

              province.add(mesh);
              province.add(line)



            })

          })

          // 将geo的属性放到省份模型中
          province.properties = elem.properties;
          if (elem.properties.contorid) {
            const [x, y] = projection(elem.properties.contorid);
            province.properties._centroid = [x, y];
          }

          map.add(province);

        })


        scene.add(map);

    }


    function animate() { // 设置动画

      const time = Date.now() * 0.0005;

      AnimationFrameAll =  requestAnimationFrame(animate);

      raycaster.setFromCamera(mouse, camera);
  
      let intersects = raycaster.intersectObjects(scene.children, true);

      if (activeInstersect && activeInstersect.length > 0)  {
        activeInstersect.forEach(element => {
          element.object.material[0].color.set('#3A3E44');
          element.object.material[1].color.set('#3A3E44');
        });
      }

      activeInstersect = []


      for (var i = 0; i < intersects.length; i++) {
        if (intersects[i].object.material && intersects[i].object.material.length === 2) {
          activeInstersect.push(intersects[i]);
          intersects[i].object.material[0].color.set(0x59C3F9);
          intersects[i].object.material[1].color.set(0x59C3F9);
          break; // 只取第一个
        }
      }



      if(circle1) {
        circle1.rotation.z += 0.05
      }
      if(circle2) {
        circle2.rotation.z += 0.05
      }
      if(circle3) {
        circle3.rotation.z += 0.05
      }
      if(circle4) {
        circle4.rotation.z += 0.05
      }



      if(pointScene) {

         pointScene.geometry.vertices.forEach((item) => {
            item.x -= item.direction.x * 0.2;
            item.y -= item.direction.y * 0.2;
            item.z -= item.direction.z * 0.2;
            if (item.x > width || item.x < -width) item.x = item.origin.x;
            if (item.y > width || item.y < -width) item.y = item.origin.y;
            if (item.z > width || item.z < -width) item.z = item.origin.z;
        });

        pointScene.geometry.verticesNeedUpdate = true;
      }

      
      

      if(light1 && light2 && light3 && light4) {
          light1.position.x = Math.sin( time * 0.7 ) * 30;
          light1.position.y = Math.cos( time * 0.5 ) * 40;
          light1.position.z = Math.cos( time * 0.3 ) * 30;
    
          light2.position.x = Math.cos( time * 0.3 ) * 30;
          light2.position.y = Math.sin( time * 0.5 ) * 40;
          light2.position.z = Math.sin( time * 0.7 ) * 30;
    
          light3.position.x = Math.sin( time * 0.7 ) * 30;
          light3.position.y = Math.cos( time * 0.3 ) * 40;
          light3.position.z = Math.sin( time * 0.5 ) * 30;
    
          light4.position.x = Math.sin( time * 0.3 ) * 30;
          light4.position.y = Math.cos( time * 0.7 ) * 40;
          light4.position.z = Math.sin( time * 0.5 ) * 30;
      }

      
      circleObj.rotation.z += 0.01

      renderer.render(scene, camera);

      labelRenderer.render( scene, camera );
    }


    function setRaycaster() {

      raycaster =  new THREE.Raycaster();
      mouse = new THREE.Vector2();
      eventOffset = {};


      function onMouseMove(event) {

        // calculate mouse position in normalized device coordinates
        // (-1 to +1) for both components

        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
        eventOffset.x = event.clientX;
        eventOffset.y = event.clientY;

        
        // this.provinceInfo.style.left = _this.eventOffset.x + 2 + 'px';
        // this.provinceInfo.style.top = _this.eventOffset.y + 2 + 'px';
      }

       window.addEventListener('mousemove', onMouseMove, false)

    }

    function setController() {
      // controller = new THREE.OrbitControls(camera, renderer.domElement);
      controls = new OrbitControls(camera, renderer.domElement);


    }

    function setLight() {
      let ambientLight = new THREE.AmbientLight(0xffffff); // 环境光
      scene.add(ambientLight);

      const light = new THREE.DirectionalLight();
      light.position.set( 0.5, 1, 100 );
      light.castShadow = true;
      light.shadow.camera.zoom = 4; // tighter shadow map
      scene.add( light );


    }


  return (
    <div className="EarthEchart">


    <div>
        <div id="containerHHback" className="echart" style={{width: 1400, height: 900}}>

        </div>
    </div>
    
        

    </div >
  )
}

export default withRouter(Earth);
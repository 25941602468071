import React from "react";

import {Button, Table, message, Image, Select, Modal, Form, Input, DatePicker} from "antd";
// 添加furon
import { SearchOutlined, DownOutlined, UpOutlined, PlusOutlined, BorderLeftOutlined, UploadOutlined, SyncOutlined} from '@ant-design/icons';

import './distinguishList.less';

import { Uploadhh } from "@/components/upload/upload";

import {formatTime} from '@/utils/util'

import moment from 'moment';

const {Option} =  Select;

const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 16},
};


export default class ProjectConfig extends React.Component {
  FormSbmitRef = null
  imageUrl = process.env.REACT_APP_IMG_URL
  state = {
    status: null,
    loading: false,
    tableData: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    params : {
        lengthLimit: 10,
        offsetLimit: 0,
        deviceKey: null,
        keyword: null,
        logoDoorman: null,
        manual: null,
        uiEnterpriseId: [],
        uiTeamId: [],
        uiWorkId: [],
        startingEditTime: null,
        endEditTime: null,
        status: '',   
    },
    isModalVisible: false,
    requestParmas: {},
    machineDeviceList: [],
    deviceInspectionTypeList: [],

    openStatus: false,

    enterpriseAllList: [],
    teamAllList: [],
    workAllList: [],

    doormanAllList: [],
    deviceAllList:[],
    userList: [],
    userValue: null,
    
    userMsg: {},
    
    toSaveChangeMsg: {
        deviceKey: null,
        idNum: null,
        imageUrl: null,
        showTime: null,
    },

    deviceAllListAll2: [],

  } 
  


 columns = [
  {
    title: "序号",
    dataIndex: 'title',
    align: 'center',
    render: (_, item, index) => {
      return (
        <p>{index + 1}</p>
      )
    }
  },
    {
      title: '姓名',
      dataIndex: 'uicName',
      align: 'center',
    },
    {
      
      title: '手机号码',
      dataIndex: 'uPhone',
      align: 'center',
    },
    {
      
        title: '身份证号码',
        dataIndex: 'uicNumber',
        align: 'center',
    },
    {
        title: '公司',
        dataIndex: 'eName',
        align: 'center'
    },
    {
        title: '部门',
        dataIndex: 'tName',
        align: 'center'
    },
    {
        title: '岗位',
        dataIndex: 'towName',
        align: 'center'
    },


    {
        title: '进场时间',
        dataIndex: 'enterTime',
        align: 'center',
        render: (_) => {
            let time = null
            if(_) {
                time = formatTime(new Date(_ * 1000), 'yyyy-MM-dd hh:mm:ss')
            } else {
                time = ' '
            }
            return (
                <p>
                    {time}
                </p>
            )
        }
    },
    {
        title: '手动进场',
        dataIndex: 'enterManual',
        align: 'center',
        render:(_) => {
          return (
            <p>{_ ==2?'是': '否'}</p>
          )
        }
    },


    {
        title: '出场时间',
        dataIndex: 'exitTime',
        align: 'center',
        render: (_) => {
            let time = null
            if(_) {
                time = formatTime(new Date(_ * 1000), 'yyyy-MM-dd hh:mm:ss')
            } else {
                time = ' '
            }
            return (
                <p>
                    {time}
                </p>
            )
        }
    },
    {
        title: '手动退场',
        dataIndex: 'exitManual',
        align: 'center',
        render: (_) => {
            return (
                <p>{_ ==2?'是': '否'}</p>
            )
        }
    },
     {
        title: '操作',
        dataIndex: 'address',
        align: 'center',
        width: 170,
        render:(_,item) => {
          return (
            <div className="operation">
                <p onClick={this.edit.bind(this, item)}>手动刷脸</p> 
            </div>
          )
        }
      },
  ]


  handleTableChange(pagination) {
    this.state.params.offsetLimit = (pagination.current -1) * pagination.pageSize
    this.state.params.lengthLimit = pagination.pageSize
    this.setState({
      params: this.state.params,
      pagination: pagination
    })

    // // 执行更新列表
    this.getList()
  }


  

  componentDidMount() {
    let getDate =  new Date()
    let year = getDate.getFullYear()
    let Month = getDate.getMonth() + 1
    let day = getDate.getDate()

    
    let newData = year + '/' + Month + '/' + day + ' 00:00:00'
    let endData = year + '/' + Month + '/' + day + ' 23:23:23'

    let newStart = new Date(newData);
    newStart.setDate(newStart.getDate() - 6);
    let newDatStop = new Date(endData);

    this.state.newDataHH = [moment(newStart), moment(newDatStop)]
    this.state.params.startingEditTime = parseInt((+newStart) / 1000) 
    this.state.params.endEditTime = parseInt((+newDatStop) / 1000) 


    this.getList()


    this.getEnterpriseAll()
    this.getDoormanAll()

    this.getDeviceAll('')
  }


  // 获取门卫
  getDoormanAll() {
      React.$axios.post('doormanAll').then(res => {
        this.setState({
            doormanAllList: res.data.doormanAll
        })
      })
  }

   // 获取公司  
  getEnterpriseAll() {
    React.$axios.post('enterpriseAll').then(res => {
      this.setState({
        enterpriseAllList: res.data.enterpriseAll
      })
    })
   }

   getTeamAll(id) {

    let params = {
       id: id
     }
    React.$axios.post('teamAll', params).then(res => {
      this.setState({
        teamAllList: res.data.teamAll,
        workAllList: []
      })  
    })
  }

  // 获取工种
  getWorkAll(params) {
    React.$axios.post('workAll', {id: params}).then(res => {
      this.setState({
        workAllList: res.data.workAll
      })
    })
  }

  // 获取设备
  getDeviceAll(value, type) {
    React.$axios.post('deviceAll', {logoDoorman: value}).then(res => {
        if(type) {
            res.data.deviceAll.map(item => {
                let direction = item.direction == 1? '出场': '入场'
                item.name = item.name +  `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0` +  direction
            })

            this.setState({
                deviceAllListAll2: res.data.deviceAll
            })
        } else {
            this.setState({
                deviceAllList: res.data.deviceAll
            })
        }
    })
  }


  getPersonnelCategory(type) {
    switch(type) {
      case type == 2:
        return '一般作业人员'
      case type == 3:
        return '特种作业人员'
      case type == 4:
        return '项目管理人员'
      case type == 5:
        return '其它人员'
      default:
        return '其他'
    }
  }



  getList(type) {
    this.setState({
        loading: true
    })

    if(type) {
      this.state.params.offsetLimit = 0
      this.state.pagination.current = 1

      this.setState({
        params: this.state.params,
        pagination: this.state.pagination
      })
    }


    React.$axios.post('recognitionList', this.state.params).then(res => {
         this.state.pagination.total = res.data.countList
        this.setState({
            tableData: res.data.list,
            loading: false,
        })
    })
  }

  edit(item) {

    item.showTime = moment(new Date())

    this.setState({
        requestParmas: item,
        isModalVisible: true,
        userMsg: item
    })

    this.getDeviceAll(item.logoDoorman, true)
    this.onSearch(item.uicNumber).then(res => {
        this.serchPeople(res[0].id, {item: res[0]})

    })

    this.clierFormSbmitRef()
   

  }


  clierFormSbmitRef() {
    // if(!this.FormSbmitRef) {
    //     setTimeout(() => {
          
    //         this.FormSbmitRef.resetFields()
    //     }, 500)
    // } else {
    //     this.FormSbmitRef.resetFields()
    // }
  }


  onChangeT(name, value) {
     if(name) {
        this.state.params[name] = value
        this.setState({
            params: this.state.params
        })
     } else {
         this.state.requestParmas.enterDeviceKey = value
         this.setState({requestParmas:this.state.requestParmas})
     }
    

}

  // 去查询
  toSerch() {
    this.getList(true)
  }

  handleOk() {
    let params = {
        deviceKey: this.state.requestParmas.enterDeviceKey,
        idNum: this.state.userMsg.uicNumber,
        imageUrl: null,
        showTime:  parseInt((+this.state.requestParmas.showTime._d) / 1000),
    }
    
     React.$axios.post('recognitionManual', params).then(res => {
      if(res.code == 20000) {
        message.success('保存成功')
        this.setState({
          isModalVisible: false
        })
        this.getList()
      }
     })

    // this.FormSbmitRef.submit()
  }



  handleCancel() {
    this.setState({
        isModalVisible:false
    })
  }



  onFinish(params) {
    for(let i in params) {
        this.state.requestParmas[i] = params[i]
    }

    React.$axios.post('machineCommodityEditSave', this.state.requestParmas).then(res => {
        if(res.code == 20000) {
         message.success('保存成功')
         this.getList()
         this.setState({
             isModalVisible: false
         })   
        }
    })
  }

  handleBack(val) {
    this.state.requestParmas.img = val.data.key[0]

    this.setState({
        requestParmas: this.state.requestParmas
    })
    

  }


  setInput(name,value) {
    this.state.params[name] = value.target.value
  }

  setDrapDom() {
    if(this.state.openStatus == true) {
        return (
          <UpOutlined/>
        )
      } else {
        return (
          <DownOutlined />
        )
      }
  }

  drapHeight() {
    this.setState({
        openStatus: !this.state.openStatus
      })
  }


  onChangeEnterprise(value, item) {
    item = [item]
    

    this.state.params.uiEnterpriseId = value
    this.state.params.uiTeamId = []
    this.state.params.uiWorkId = []

    this.setState({
      params: this.state.params,
      teamAllList: [],
      workAllList: []
    })

    let arr = []
    item.map(item2 => {
      if(!item2.item.teamId) {

      } else {
        let teamId = item2.item.teamId.split(',')
        arr = [...arr, ...teamId]
      }
      
    })

     this.getTeamAll(arr)
  }


  onChangeTeam(value, item) {
    item = [item]
    let arr = []
    item.map(item2 => {
      if(!item2.item.workId) {

      } else {
        let workId = item2.item.workId.split(',')
       arr = [...arr, ...workId]
      }
      
    })

    this.state.params.uiTeamId = value
    this.state.params.uiWorkId = []

    this.setState({
      params: this.state.params,
      workAllList: []
    })

    this.getWorkAll(arr)
  }


  onChangeWorkAll(value) {
    this.state.params.uiWorkId = value
    this.state.params.deviceKey = null
    this.setState({
      params: this.state.params
    })
  }

  onChangeDoor(status,value) {
    
    if(status) { // 详情
        this.state.requestParmas.logoDoorman = value
        this.state.requestParmas.enterDeviceKey = null

        this.setState({
            requestParmas: this.state.requestParmas
        })

    } else { // 搜索条件
        this.state.params.logoDoorman = value
        this.state.params.deviceKey = null
        this.setState({
            params: this.state.params
        })
    }

    

    this.getDeviceAll(value, status)
  }


  // 查找人员信息
  serchPeople(val, item) {

    this.setState({
        userValue : val,
        userMsg: item.item
    })
  }

  // 查询
  onSearch(val) {
    return new Promise(resolve => {
        React.$axios.post('userList', {export: 2 ,keyword: val}).then(res => {
            this.setState({
                userList: res.data.list
            })
            resolve(res.data.list)
        })
    })
    
  }

  // 改变时间
  onChangeTime(value) {
    this.state.newDataHH = value
    
    this.state.requestParmas.showTime = value

    this.setState({
        newDataHH: this.state.newDataHH,
        requestParmas:this.state.requestParmas
    })      
  }




  render() {
    return (
      <div className="distinguishList">

        <div className="warp1">
                <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                    <li><span>公司:</span> 
                            <Select
                                    showSearch
                                    style={{ width: 200, marginRight:20, }}
                                    placeholder="请选择公司"
                                    optionFilterProp="children"
                                    onChange={this.onChangeEnterprise.bind(this)}
                                >
                                 {
                                   this.state.enterpriseAllList.map(item => {
                                     return (
                                      <Option item={item} value={item.id}>{item.name}</Option>
                                     )
                                   })
                                 }

                            </Select>
                        </li>
                        <li><span>部门:</span>
                            <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择部门"
                                    optionFilterProp="children"
                                    value = {this.state.params.uiTeamId}
                                    onChange={this.onChangeTeam.bind(this)}
                                >                         
                                {
                                   this.state.teamAllList.map(item => {
                                     return (
                                      <Option item={item} value={item.id}>{item.name}</Option>
                                     )
                                   })
                                 }

                                </Select> 
                        </li>

                        <li><span>岗位:</span>
                            <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择岗位"
                                    optionFilterProp="children"
                                    value = {this.state.params.uiWorkId}
                                    onChange={this.onChangeWorkAll.bind(this)}
                                >
                                {
                                   this.state.workAllList.map(item => {
                                     return (
                                      <Option item={item} value={item.id}>{item.name}</Option>
                                     )
                                   })
                                 }
                             
                                </Select>
                        </li>

                        <li><span>门卫:</span> 
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    onChange={this.onChangeDoor.bind(this, false)}
                                >
                                  <Option value=' '>全部</Option>
                                    {
                                        this.state.doormanAllList.map(item => {
                                            return (
                                                <Option value={item.logo}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                        </li>

                        <li><span>设备:</span> 
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    value = {this.state.params.deviceKey}
                                    onChange={this.onChangeT.bind(this, 'deviceKey')}
                                >
                                    {
                                        this.state.deviceAllList.map(item => {
                                            return (
                                                <Option value={item.deviceKey}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                        </li>

                        <Button  className="btn" style={{display: this.state.openStatus?'none':''}} onClick={this.toSerch.bind(this)}>
                            <SearchOutlined className="icon"/>
                        </Button> 


                        <li><span>手动刷脸:</span> 
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    onChange={this.onChangeT.bind(this, 'manual')}
                                >
                                    <Option value=" " key="0">全部</Option>    
                                    <Option value="2" key="1">是</Option>
                                    <Option value="3" key="2">否</Option>
                                </Select>
                        </li>

                        <li><span>进退场:</span> 
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    onChange={this.onChangeT.bind(this, 'status')}
                                >
                                    <Option value=" " key="0">全部</Option>    
                                    <Option value="2" key="1">进场</Option>
                                    <Option value="3" key="2">退场</Option>
                                </Select>
                        </li>


                        <li><span>时间:</span> 
                            <RangePicker value={this.state.newDataHH} showTime style={{ width: 350, height:30, marginRight:20,  }} onChange={this.onChangeTime.bind(this)} />   
                        </li>

                                 

                        <li><span>模糊查询:</span> 
                            <Input style={{ width: 200, height:30, marginRight:20, } } onChange={this.setInput.bind(this, 'keyword')}  placeholder="请输入姓名/身份证,手机号" />
                        </li>

                            <Button  className="btn" onClick={this.toSerch.bind(this)} style={{display: this.state.openStatus?'':'none'}}>
                                <SearchOutlined  className="icon"/>
                            </Button>

                          
                    </ul>

                    <p class="drapBottom" onClick={this.drapHeight.bind(this)}>
                        {
                          this.setDrapDom()
                        }
                    </p>
                </div>


                <div className="table">
                <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                loading={this.state.loading} rowKey={record => record.id}  columns={this.columns} dataSource={this.state.tableData}  />

                </div>
            
            </div>


            
            
            <Modal title="手动刷脸" width="1400px" className="distinguishListModal" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>

                          <div className="modalShow">
    
                            <div className="changeMsg">
                                <h3>选择信息</h3>
                                <ul>
                                    <li>
                                        <p>门卫:</p>
                                        <Select
                                            showSearch
                                            style={{ width: 300, height:30, marginRight:20,  }}
                                            placeholder="请选择"
                                            optionFilterProp="children"
                                            value = {this.state.requestParmas.logoDoorman}
                                            onChange={this.onChangeDoor.bind(this, true)}
                                        >
                                            <Option value="">全部</Option>
                                            {
                                                this.state.doormanAllList.map(item => {
                                                    return (
                                                        <Option value={item.logo}>{item.name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </li>
                                    <li>
                                        <p>设备:</p>
                                        <Select
                                            showSearch
                                            style={{ width: 300, height:30, marginRight:20,  }}
                                            placeholder="请选择"
                                            optionFilterProp="children"
                                            value = {this.state.requestParmas.enterDeviceKey}
                                            onChange={this.onChangeT.bind(this, false)}
                                        >
                                            {
                                                this.state.deviceAllListAll2.map(item => {
                                                    return (
                                                        <Option value={item.deviceKey}>{item.name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </li>
                                    <li>
                                        <p>识别时间:</p>
                                            <DatePicker showTime value={this.state.requestParmas.showTime} style={{ width: 300, height:30, marginRight:20,  }} onChange={this.onChangeTime.bind(this)} />
                                    </li>
                                </ul>
                            </div>


                            <div className="personMsg">
                                <h3>人员信息</h3>
                                <ul>
                                    <li>
                                        <div className="flex">
                                            <p>人员:</p>

                                            <Select
                                                showSearch
                                                value={this.state.userValue}
                                                style={{ width: 300, height:30, marginRight:20,  }}
                                                placeholder="请选择"
                                                defaultActiveFirstOption={false}
                                                showArrow={false}
                                                filterOption={false}
                                                onSearch={this.onSearch.bind(this)}
                                                onChange={this.serchPeople.bind(this)}
                                                notFoundContent={null}
                                            >
                                                 {
                                                    this.state.userList.map((item, index) => {
                                                        return (
                                                            <Option value={item.id} item={item} key={index}>{item.uicName}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </div>

                                        <div className="flex">
                                            <p>图片:</p>
                                           <p>
                                                                <Image
                                                                        width={100}
                                                                        height={100}
                                                                        src={this.imageUrl + this.state.userMsg.uiAvatarKey}
                                                                    /></p> 
                                        </div>

                                        <div className="flex">
                                            <p>电话:</p>
                                            <p>{this.state.userMsg.phone}</p>
                                        </div>
                                    </li>
                                    <li>
                                       <div className="flex">
                                            <p>公司:</p>
                                            <p>{this.state.userMsg.eName}</p>
                                       </div>

                                       <div className="flex">
                                            <p>部门:</p>
                                            <p>{this.state.userMsg.teName}</p>
                                       </div>

                                       <div className="flex">
                                            <p>岗位:</p>
                                            <p>{this.state.userMsg.nameWork}</p>
                                       </div>

                                    </li>
                                    <li>
                                        <div className="flex">
                                            <p>身份证号:</p>
                                            <p>{this.state.userMsg.uicNumber}</p>
                                       </div>

                                       <div className="flex">
                                            <p>人员类别:</p>
                                            <p>{this.getPersonnelCategory(this.state.userMsg.uiPersonnelCategory)}</p>
                                       </div>

                                       <div className="flex">
                                            <p>角色:</p>
                                            <p>{this.state.userMsg.uiRole == 3? '管理人员': '劳务人员'}</p>
                                       </div>
                                    </li>
                                </ul>


                            </div>
                          
                          </div>

            </Modal> 



      </div>

    );
  }
}

import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import './earth.less'

// import * as echarts from 'echarts';

import * as THREE from './three.module.js';

import { OrbitControls } from './OrbitControls.js';


import { OBJLoader } from './threeSouce/OBJLoader.js';
import { MTLLoader } from './threeSouce/MTLLoader.js';
import { DDSLoader } from './threeSouce/DDSLoader.js';


import * as d3 from 'd3';


import full from './full.json';



// 地球圆圈
import img3 from '@/assets/img/earth/1.png';
 
import tu1 from '@/assets/img/earth/tu1.png';



import objModle from '@/assets/img/modle/biaoji/canmera.obj';
import mtl from '@/assets/img/modle/biaoji/canmera.mtl';

function Earth(props) {
    let container  = null
    let map = null,
    renderer = null,
    scene = null,
    camera = null,
    controller = null,
    raycaster = null,
    mouse = null,
    eventOffset = null,
    provinceInfo = null,
    controls = null,
    activeInstersect = [],
    uniform = {},
    light1 = null,
    light2 = null,
    light3 = null,
    light4 = null,
    object = null,
    texture = null,
    object1 = null,
    object2 = null,
    object3 = null,
    object4 = null,
    circle1 = null,
    circle2 = null,
    circle3 = null,
    circle4 = null,
    bottomCicleObj = new THREE.Object3D(), 
    circleObj = new THREE.Object3D(),
    AnimationFrameRender = null,
    AnimationFrameAll = null

    const [propsState, setPropsState] = useState(props)

    useEffect(() => {

      getgetThreeEarth()

      
      return () => {
        cancelAnimationFrame(AnimationFrameRender); 
        cancelAnimationFrame(AnimationFrameAll); 
        window.removeEventListener('mousemove', () => {

        }) 

        clearDom()

      }

      }, propsState)

    function clearDom() {
      container  = null
      map = null
      renderer = null
      scene = null
      camera = null
      controller = null
      raycaster = null
      // mouse = null
      // eventOffset = null
      provinceInfo = null
      controls = null
      activeInstersect = null
      uniform = null
      light1 = null
      light2 = null
      light3 = null
      light4 = null
      object = null
      texture = null
      object1 = null
      object2 = null
      object3 = null
      object4 = null
      circle1 = null
      circle2 = null
      circle3 = null
      circle4 = null
      bottomCicleObj = null
      circleObj = null
      AnimationFrameRender = null
      AnimationFrameAll = null

    }

    
    // three.js 模型 
    function getgetThreeEarth() {
      container = document.getElementById("containerHHback");

      renderer = new THREE.WebGLRenderer();
      
      renderer.setClearColor('#0D1E37', 1.0);
      

      renderer.setSize(window.innerWidth, window.innerHeight);
      container.appendChild(renderer.domElement);

      scene = new THREE.Scene();
      
      scene.position.x = 30;

      scene.add(circleObj)

 
      camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.set(0, -70, 80);
      camera.lookAt(0, 0, 0);

      //设置控制
      setController()

      // 设置光照
      setLight()

      // 设置射线
      setRaycaster()

      // 设置动画
      animate()

      // 加载地图
      loadMapData(full);

      // 加载圆圈
      scatterCircle(1, 0.1, 0.1, new THREE.Vector3(0, 1, 1), 0.004);
      // circle.scale.x = circle.scale.y = circle.scale.z = xScale;
      // circle.position.set(x, y, z);
      // scene.add(circle);
    
      // 加载粒子

      // 加载圆圈
      setCircleImg()


      // 加载发光物体


      // 加载光点
      sphereLight()

      // 加载模型
      loadModle()


      // 加载弹框
      // addCanvas('中国水电十四局土木事业部').then(mesh1 => {
      //     mesh1.position.z = 25
      //     mesh1.position.y = -28
      //     mesh1.position.x = -10
      //     mesh1.scale.x = mesh1.scale.y = mesh1.scale.z = 0.3;
      //     mesh1.rotateX(Math.PI/180 * 50)
      //     mesh1.rotateY(Math.PI/180 * 0)

      //     console.log(mesh1)

      //     scene.add(mesh1);
      // }) 
    }

    // 加载字体
    function addCanvas(fontText) {

      return new Promise(reslove => {
        // let image = new Image();
        // image.src= img31;
        // image.onload =()=>{
          var canvas = document.createElement('canvas');
          // const {width, height} = image;
          // canvas.style.width = width+'px';
          // canvas.style.height = height+'px';

          const width = 200
          const height = 150


          var scale = window.devicePixelRatio;
           var scale = 1; //我为什么用2，而不用devicePixelRatio数值？因为我的电脑Win10系统设置了自定义缩放比大于devicePixelRatio但小于2（200%）
          canvas.width = Math.floor(width*scale);
          canvas.height = Math.floor(height*scale);
          
          var ctx = canvas.getContext('2d');
          ctx.fillStyle = "rgba(0,0,0, 0.8)";
			    ctx.fillRect(0, 0, width, height);
          

          
          ctx.scale(scale, scale);//scale>1表示放大，反之表示缩小。即绘制原先1px现在是绘制scale个pixel　　 //注意绘制内容在scale之后开始
          // ctx.drawImage(image, 0, 0);


          ctx.fillStyle = '#ffffff';
          ctx.font='20px Arial';
          ctx.textAlign = 'center';
          // ctx.textBaseline = 'middle';
          var x = canvas.width/2;
          var y = canvas.height/2;
          ctx.fillText(fontText, x/scale, y/scale);//为什么x/y还除以scale，因为画布实际放大了scale倍，所以x,y绘制的像素也放大了scale，所以再除以scale
          // var image1 = new Image();
          // image1.src = canvas.toDataURL();
          let  geometry=new THREE.CubeGeometry(20,10,0);
          let texture=new THREE.Texture(canvas);
          let material=new THREE.MeshBasicMaterial({map:texture, transparent: true});
          texture.needsUpdate=true;//开启纹理更新
          let mesh=new THREE.Mesh(geometry,material);
          reslove(mesh)
        // }
      });
    }

    





    
    function loadModle() {
      const ObjGroup = new THREE.Object3D()
      ObjGroup.name = 'ObjGroup';


      // tu1

      // 加载底部圈圈
      const geometry = new THREE.CircleGeometry(1, 30);

      // // 0C2940

      var texture = THREE.ImageUtils.loadTexture(tu1, {}, function() {
          
      });
      const material = new THREE.MeshLambertMaterial({
            map: texture,
            transparent: true,
      });

      circle1 = new THREE.Mesh(geometry, material);
      circle1.position.z = 4
      circle1.position.y = -20
      circle1.position.x = -3
      ObjGroup.add(circle1)


      circle2 = circle1.clone()
      circle2.position.x = 0;
      circle2.position.y = -10;
      ObjGroup.add(circle2)


      circle3 = circle1.clone()
      circle3.position.x = 13;
      circle3.position.y = -23;
      ObjGroup.add(circle3)


      circle4 =  circle1.clone();
      circle4.position.y = -5;
      circle4.position.x = 12;
      ObjGroup.add(circle4)




      // circle3 = 



      
      const manager = new THREE.LoadingManager();
				manager.addHandler( /\.dds$/i, new DDSLoader() );

      new MTLLoader( manager )
					.load( mtl, function ( materials ) {

						materials.preload();

						new OBJLoader( manager )
							.setMaterials( materials )
							.load( objModle, function ( object ) {

								object.position.y = -20;
                object.position.x = -3;
                object.position.z = 10;
                object.rotateX(-Math.PI/180 * 20);
                object.rotateZ(-Math.PI/180 * 50);

        
                object.scale.x = object.scale.y = object.scale.z = 0.3;

                object1 = object
                ObjGroup.add(object1)

                object3 =  object.clone();
                object3.position.x = 13;
                object3.position.y = -23;

                ObjGroup.add(object3)
                
                object2 = object.clone();
                object2.position.x = 0;
                object2.position.y = -10;
                ObjGroup.add(object2);

                object4 =  object.clone();
                object4.position.y = -5;
                object4.position.x = 12;
                ObjGroup.add(object4);
                
                scene.add( ObjGroup );
							} );
					} );
    }



    function sphereLight() {

      const lightGroup = new THREE.Object3D()
      lightGroup.name = 'lightGroup'

      const sphere = new THREE.SphereGeometry( 0.5, 16, 8 );

      light1 = new THREE.PointLight( 0xff0040, 2, 50 );
      light1.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0xff0040 } ) ) );
      // scene.add(  );
      lightGroup.add(light1)

      light2 = new THREE.PointLight( 0x0040ff, 2, 50 );
      light2.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0x0040ff } ) ) );
      // scene.add(  );
      lightGroup.add(light2)

      light3 = new THREE.PointLight( 0x80ff80, 2, 50 );
      light3.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0x80ff80 } ) ) );
      // scene.add(  );
      lightGroup.add(light3)

      light4 = new THREE.PointLight( 0xffaa00, 2, 50 );
      light4.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0xffaa00 } ) ) );
      // scene.add(  );
      lightGroup.add(light4)

      scene.add(lightGroup)

      // var coreGeom = new THREE.IcosahedronGeometry(10, 0);
      // var coreMat = new THREE.MeshPhongMaterial({
      //     flatShading: true
      // });

      // var lightsRing = new THREE.Object3D();
      // lightsRing.name = 'lightsRing';
      // scene.add(lightsRing);

      // let slice =  Math.PI * 2 / 5;
      // let radius = 1;

      // for (var i = 0; i < 5; i++) {
      //     var color = new THREE.Color(Math.random(), Math.random(), Math.random());
      //     var posx = Math.cos(slice * i) * radius;
      //     var posy = Math.sin(slice * i) * radius;

      //     var light = getPointLightObject(posx, posy, 0, lightData.radius, color);
      //     lights.push(light);
      //     lightsRing.add(light);
      // }

  
      // var core = new THREE.Mesh(coreGeom, coreMat);
      // core.name = 'core';

      // scene.add(core)

    }

    


    
    function setCircleImg() {
      const geometry = new THREE.CircleGeometry(60, 120);

      // 0C2940

      var texture = THREE.ImageUtils.loadTexture(img3, {}, function() {
          
      });
      const material = new THREE.MeshLambertMaterial({
            map: texture,
            transparent: true,
      });

      const circle = new THREE.Mesh(geometry, material);

      circleObj.add(circle)
    }

    

    // 加载圆圈
    function scatterCircle(r, init, ring, color, speed) {
      
      uniform = {
        u_color: { value: color },
        u_r: { value: init },
        u_ring: {
          value: ring,
        },
      };
    
      var vs = `
        varying vec3 vPosition;
        void main(){
          vPosition=position;
          gl_Position	= projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `;
      var fs = `
        varying vec3 vPosition;
        uniform vec3 u_color;
        uniform float u_r;
        uniform float u_ring;
    
        void main(){
          float pct=distance(vec2(vPosition.x,vPosition.y),vec2(0.0));
          if(pct>u_r || pct<(u_r-u_ring)){
            gl_FragColor = vec4(1.0,0.0,0.0,0);
          }else{
            float dis=(pct-(u_r-u_ring))/(u_r-u_ring);
            gl_FragColor = vec4(u_color,dis);
          }
        }
      `;
      const geometry = new THREE.CircleGeometry(r, 120);
      var material = new THREE.ShaderMaterial({
        vertexShader: vs,
        fragmentShader: fs,
        side: THREE.DoubleSide,
        uniforms: uniform,
        transparent: true,
        depthWrite: false,
      });
      const circle = new THREE.Mesh(geometry, material);
      circle.scale.x = circle.scale.y = circle.scale.z = 200;
      // circleObj.rotateX(-Math.PI/180 * 90);
      circleObj.add(circle)
    
      function render() {
        uniform.u_r.value += speed || 0.1;
        if (uniform.u_r.value >= r) {
          uniform.u_r.value = init;
        }
        AnimationFrameRender = requestAnimationFrame(render);
      }
      render();
      return circle;
    }




    
    function loadMapData(chinaJson){

      map = new THREE.Object3D();

      // map.rotateX(-Math.PI/180 * 90);
      
      const projection = d3.geoMercator().center([104.0, 37.5]).scale(80).translate([0, 0]);
      chinaJson.features.forEach(elem => {
          // 定一个省份3D对象
          const province = new THREE.Object3D();
          // 每个的 坐标 数组
          const coordinates = elem.geometry.coordinates;

        let uv = `
           uniform sampler2D baseTexture;
           uniform sampler2D bloomTexture;
           varying vec2 vUv;
           vec4 getTexture( sampler2D texelToLinearTexture ) {
             return mapTexelToLinear( texture2D( texelToLinearTexture , vUv ) );
           }
          void main() {
             gl_FragColor = ( getTexture( baseTexture ) + vec4( 1.0 ) * getTexture( bloomTexture ) );
          }
        `

          // 循环坐标数组
          coordinates.forEach(multiPolygon => {

            multiPolygon.forEach(polygon => {
              const shape = new THREE.Shape();

              const lineMaterial = new THREE.LineBasicMaterial({
                color: '#91FFFF'
              });
              // const lineMaterial = new THREE.ShaderMaterial({
              //   vertexShader: vs,
              //   fragmentShader: fs,
              //   side: THREE.DoubleSide,
              //   uniforms: uniform,
              //   transparent: true,
              //   depthWrite: false,
              // });

              

              const lineGeometry = new THREE.Geometry();

              for (let i = 0; i < polygon.length; i++) {
                const [x, y] = projection(polygon[i]);
                if (i === 0) {
                  shape.moveTo(x, -y);
                }
                shape.lineTo(x, -y);
                lineGeometry.vertices.push(new THREE.Vector3(x, -y, 4.01));
              }

              const extrudeSettings = {
                depth: 4,
                bevelEnabled: false
              };

              const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
              const material = new THREE.MeshBasicMaterial({
                color: '#5B6166',
                transparent: true,
                opacity: 0.6
              });
              const material1 = new THREE.MeshBasicMaterial({
                color: '#5B6166',
                transparent: true,
                opacity: 0.5
              });
              const mesh = new THREE.Mesh(geometry, [material, material1]);
              const line = new THREE.Line(lineGeometry, lineMaterial);
              
              province.add(mesh);
              province.add(line)
            })

          })

          // 将geo的属性放到省份模型中
          province.properties = elem.properties;
          if (elem.properties.contorid) {
            const [x, y] = projection(elem.properties.contorid);
            province.properties._centroid = [x, y];
          }

          map.add(province);

        })


        scene.add(map);

    }


    function animate() { // 设置动画

      const time = Date.now() * 0.0005;

      AnimationFrameAll =  requestAnimationFrame(animate);

      raycaster.setFromCamera(mouse, camera);
  
      let intersects = raycaster.intersectObjects(scene.children, true);

      if (activeInstersect && activeInstersect.length > 0)  {
        activeInstersect.forEach(element => {
          element.object.material[0].color.set('#5B6166');
          element.object.material[1].color.set('#5B6166');
        });
      }

      activeInstersect = []


      for (var i = 0; i < intersects.length; i++) {
        if (intersects[i].object.material && intersects[i].object.material.length === 2) {
          activeInstersect.push(intersects[i]);
          intersects[i].object.material[0].color.set(0x59C3F9);
          intersects[i].object.material[1].color.set(0x59C3F9);
          break; // 只取第一个
        }
      }

      if(object3) {
        object3.position.z = 5 + Math.cos( time * 5 ) * 1;
      }

      if(object2) {
        object2.position.z = 5 + Math.cos( time * 5 ) * 1;
      }

      if(object1) {
        object1.position.z = 5 + Math.cos( time * 5 ) * 1;
      }

      if(object4) {
        object4.position.z = 5 + Math.cos( time * 5 ) * 1;
      }


      if(circle1) {
        circle1.rotation.z += 0.01
      }




      if(light1 && light2 && light3 && light4) {
          light1.position.x = Math.sin( time * 0.7 ) * 30;
          light1.position.y = Math.cos( time * 0.5 ) * 40;
          light1.position.z = Math.cos( time * 0.3 ) * 30;
    
          light2.position.x = Math.cos( time * 0.3 ) * 30;
          light2.position.y = Math.sin( time * 0.5 ) * 40;
          light2.position.z = Math.sin( time * 0.7 ) * 30;
    
          light3.position.x = Math.sin( time * 0.7 ) * 30;
          light3.position.y = Math.cos( time * 0.3 ) * 40;
          light3.position.z = Math.sin( time * 0.5 ) * 30;
    
          light4.position.x = Math.sin( time * 0.3 ) * 30;
          light4.position.y = Math.cos( time * 0.7 ) * 40;
          light4.position.z = Math.sin( time * 0.5 ) * 30;
      }

     


      circleObj.rotation.z += 0.01

      renderer.render(scene, camera);
    }


    function setRaycaster() {

      raycaster =  new THREE.Raycaster();
      mouse = new THREE.Vector2();
      eventOffset = {};


      function onMouseMove(event) {

        // calculate mouse position in normalized device coordinates
        // (-1 to +1) for both components

        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
        eventOffset.x = event.clientX;
        eventOffset.y = event.clientY;

        
        // this.provinceInfo.style.left = _this.eventOffset.x + 2 + 'px';
        // this.provinceInfo.style.top = _this.eventOffset.y + 2 + 'px';
      }

       window.addEventListener('mousemove', onMouseMove, false)

    }

    function setController() {
      // controller = new THREE.OrbitControls(camera, renderer.domElement);
      controls = new OrbitControls(camera, renderer.domElement);


    }

    function setLight() {
      let ambientLight = new THREE.AmbientLight(0xffffff); // 环境光
      scene.add(ambientLight);
    }


    // 
    // function init() {
    //   //let loader = new THREE.FileLoader();


    //   // loader.load(full, function (data) {
    //     // console.log(data)
    //     // let jsonData = JSON.parse(data);
    //     initMap(full); // 解析并绘制地图
    //   //});

   


    // function getEcharts() {

    //     var dom = document.getElementById("containerHHback");

    //     var myChart = echarts.init(dom);
        
    //     myChart.hideLoading();

          


    //       echarts.registerMap('nb', full);
    //       const option = {
    //         tooltip: {
    //           trigger: 'item',
    //         },
    //         visualMap: {
    //             min: 0,
    //             max: 0,
    //           realtime: true,
    //           calculable: false,
    //           itemWidth: 0,
    //           inRange: {
    //             color: ['#ffffff', 'rgba(59,63,68, 0.2)', '#ffffff']
    //           },
              

    //           textStyle: {
    //                 color: '#fff'
    //           }    
    //         },
    //         series: [
    //           {
    //             // name: '诉求量',
    //             type: 'map',
    //             mapType: 'nb', // 自定义扩展图表类型
    //             label: {
    //                 show: true,

    //                 textStyle: {
    //                     color: '#fff'
    //                 },
    //             },
    //           }
    //         ]
    //       };
    //       myChart.setOption(option);
    //     //  //随窗口大小自动改变
    //       window.onresize = myChart.resize;
    // }


  
  return (
    <div className="EarthEchart">


     


    <div>
        <div id="containerHHback" className="echart" style={{width: 1400, height: 900}}>

        </div>
        {/* <img className="img1" src={animated} alt="" />
        <img className="img2" src={ima2} alt="" />      */}

        {/* <img style={{zIndex: 100}} className="img1" src={animated} alt="" />  */}
    </div>
    
        

    </div >
  )
}

export default withRouter(Earth);
import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Table, Progress } from 'antd';
import style from './progressMonitoring.module.less';
import * as echarts from 'echarts';
import folder from '@/assets/img/DrawingList/文件夹 (7).png'

function ProgressMonitoring() {
  const dataSource = [
    {
      key: 1,
      name: 'John Brown sr.',
      age: 60,
      address: 'New York No. 1 Lake Park',
      children: [
        {
          key: 11,
          name: 'John Brown',
          age: 42,
          address: 'New York No. 2 Lake Park',
        },
        {
          key: 12,
          name: 'John Brown jr.',
          age: 30,
          address: 'New York No. 3 Lake Park',
          children: [
            {
              key: 121,
              name: 'Jimmy Brown',
              age: 16,
              address: 'New York No. 3 Lake Park',
            },
          ],
        },
        {
          key: 13,
          name: 'Jim Green sr.',
          age: 72,
          address: 'London No. 1 Lake Park',
          children: [
            {
              key: 131,
              name: 'Jim Green',
              age: 42,
              address: 'London No. 2 Lake Park',
              children: [
                {
                  key: 1311,
                  name: 'Jim Green jr.',
                  age: 25,
                  address: 'London No. 3 Lake Park',
                },
                {
                  key: 1312,
                  name: 'Jimmy Green sr.',
                  age: 18,
                  address: 'London No. 4 Lake Park',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      key: 2,
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
    },
  ];

  const columns = [
    {
      title: '编号',
      dataIndex: 'name',
      key: 'name',
      width: '200px',
      render: (text, record, index) => {
        return (
          <div >
            <img className={style.folder} src={folder}></img>
            <span>{index}</span>
          </div>
        )
      }
    },
    {
      title: '名称',
      dataIndex: 'age',
      key: 'age',
      width: '300px',
    },
    {
      title: '计划完成%',
      dataIndex: 'address',
      key: 'address',
      render: (text, record, index) => {
        return (
          <div >
            <Progress percent={30} />
          </div>
        )
      }
    },
    {
      title: '实际完成%',
      dataIndex: 'address',
      key: 'address',
      render: (text, record, index) => {
        return (
          <div >
            <Progress percent={30} />
          </div>
        )
      }
    },
    {
      title: '差值%',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '预算值（万元）',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '计划值（万元）',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '赢得值（万元）',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '进度差值（万元）',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '数据日期',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '最新反馈日期',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '责任人',
      dataIndex: 'address',
      key: 'address',
    },
  ];
  useEffect(() => {
    drawOutputValuechart();
  }, []);

  const drawOutputValuechart = () => {
    let outputValuechart = echarts.init(
      document.getElementById("outputValuechart")
    );
    outputValuechart.setOption({
      title: {
        text: '本月产值趋势',
        left: "2%"
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        }
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      legend: {
        data: ['本月累计产值金额', '每日产值金额'],
        right: "3%"
      },
      xAxis: [
        {
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisPointer: {
            type: 'shadow'
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          // name: '水量',
          min: 0,
          max: 250,
          interval: 50,
          axisLabel: {
            formatter: '{value} 万元'
          }
        },
        {
          type: 'value',
          // name: '温度',
          min: 0,
          max: 25,
          interval: 5,
          axisLabel: {
            formatter: '{value} 万元'
          }
        }
      ],
      series: [
        {
          name: '本月累计产值金额',
          type: 'bar',
          data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3]
        },
        {
          name: '每日产值金额',
          type: 'line',
          yAxisIndex: 1,
          data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
        }
      ]
    });
  }
  return (
    <div className={style.progressMonitoring}>
      <div className={style.contentTop}>
        <div className={style.topLeft}>
          <div className={style.monthOutput}>
            <p>本月累计产值(万元)</p>
            <p>￥83213.32</p>
          </div>
          <div className={style.listItem}>
            <p>今日新增产值金额</p>
            <p>
              <span className={style.number}>6554.52</span>
              <span className={style.unit}>万元</span>
            </p>
          </div>
          <div className={style.listItem}>
            <p>今日新增支付金额</p>
            <p>
              <span className={style.number}>6554.52</span>
              <span className={style.unit}>万元</span>
            </p>
          </div>
          <div className={style.listItem}>
            <p>本月累计完成产值比例</p>
            <p>
              <span className={style.number}>32.31%</span>
            </p>
          </div>
        </div>
        <div className={style.topRight}>
          <div className={style.outputValuechart} id="outputValuechart"></div>
        </div>
      </div>
      <div className={style.contentBottom}>
        <Table size="small" dataSource={dataSource} columns={columns} bordered scroll={{ y: 220 }} />;
      </div>
    </div>
  )
}
export default withRouter(ProgressMonitoring);